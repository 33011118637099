import * as network from '../../network';

const twoFactorMixins = {
    data() {
        return {
            $_twoFactorMixins_is_loaded: false,
            twoFactorMixinsAuthMethods: [],
        };
    },
    mounted() {
        this.$_twoFactorMixins_initiate();
    },
    computed: {
        $_twoFactorMixins_twoFactorAuthMethods() {
            return this.twoFactorMixinsAuthMethods;
        },
        $_twoFactorMixins_selectedTwoFactorMethod() {
            const { twoFactorAuth } = this.$store.state.user;
            if (!twoFactorAuth) return null;
            const { userTwoFactorAuthMethodId } = twoFactorAuth;
            const { twoFactorMixinsAuthMethods } = this;
            return twoFactorMixinsAuthMethods.find((item) => item.userTwoFactorAuthMethodId == userTwoFactorAuthMethodId) || null;
        },
    },
    watch: {
        '$store.state.user.school.schoolId': {
            handler() {
                this.$_twoFactorMixins_is_loaded = false;
                this.$_twoFactorMixins_initiate();
            },
        },
        key() {
            this.$_twoFactorMixins_is_loaded = false;
            this.$_twoFactorMixins_initiate();
        },
    },
    methods: {
        $_twoFactorMixins_initiate() {
            let isLoaded = this.$_twoFactorMixins_is_loaded;
            if (isLoaded) return;
            const { showError } = this;

            loadTwoFactorSetings(this.$store.state, (err, res) => {
                isLoaded = true;
                if (err) return showError(err);
                this.twoFactorMixinsAuthMethods = res.twoFactorAuthMethods;
            });
        },
    },
};

function loadTwoFactorSetings(state, callback) {
    const { schoolId, schoolTermId } = state.user.school;
    const params = {
        url: { schoolId, schoolTermId },
    };

    network.cipher.getUserTwoFactorAuthMethods(params, (err, twoFactorAuthMethods) => {
        if (err) return callback(err);
        callback(null, { twoFactorAuthMethods });
    });
}
export {
    twoFactorMixins as default,
    loadTwoFactorSetings,
};
