<template>
<div
    v-if="twoFactorMethod"
    :key="key"
    class="kt-portlet kt-portlet--mobile"
>
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <span class="kt-port    let__head-icon">
                <SVGIcon name="encrypted" />
            </span>
            <h3 class="kt-portlet__head-title">
                Confirm Account Access
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="form-group row">
            <label class="col-lg-3 col-form-label">One Time Password:</label>
            <div class="col-lg-6 one-time-pass">
                <input
                    v-model="oneTimePassword"
                    type="number"
                    step="1"
                    class="text-center form-control"
                    @keyup.enter="validateOTP"
                >
                <div class="form-text text-muted">
                    A code has been sent to {{ twoFactorMethod.twoFactorAddress }} please enter it above.
                </div>
            </div>
        </div>

        <div
            v-if="!isInVerification"
            class="form-group row"
        >
            <label class="col-lg-3 col-form-label">Session Length:</label>
            <div class="col-lg-6">
                <div class="kt-radio-inline">
                    <label class="kt-radio kt-radio--tick kt-radio--warning">
                        <input
                            v-model="sessionLength"
                            type="radio"
                            value="180"
                        > 3 hours
                        <span />
                    </label>
                    <label class="kt-radio kt-radio--tick kt-radio--danger">
                        <input
                            v-model="sessionLength"
                            type="radio"
                            value="480"
                        > 8 hours
                        <span />
                    </label>
                    <label class="kt-radio kt-radio--tick kt-radio--success">
                        <input
                            v-model="sessionLength"
                            type="radio"
                            value="1440"
                        > 1 Day
                        <span />
                    </label>
                    <label class="kt-radio kt-radio--tick kt-radio--primary">
                        <input
                            v-model="sessionLength"
                            type="radio"
                            value="10080"
                        > 1 Week
                        <span />
                    </label>
                </div>
                <div class="form-text text-muted">
                    Please make sure this is a secured private computer
                    inaccessible to students if requesting
                    an extended session.
                </div>
            </div>
        </div>
    </div>
    <div class="kt-portlet__foot">
        <div>
            <button
                class="btn btn-success pull-right"
                :disabled="saving"
                :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                @click.stop.prevent="validateOTP"
            >
                <i
                    v-if="!saving"
                    class="fa fa-lock"
                />
                {{ isInVerification ? "Verify" : "Unlock" }}
            </button>
            <button
                class="btn btn-secondary"
                @click.stop.prevent="back"
            >
                <i class="fa fa-history" />
                Request a New Code
            </button>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import * as network from '../../network';
import Types from '../../store/Types';
import twoFactorMixins from '../../store/mixins/twoFactorMixins';

export default Vue.extend({
    name: 'ChallengeOneTimePassword',
    mixins: [twoFactorMixins],
    data() {
        return {
            key: 0,
            saving: false,
            sessionLength: '180',
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        deviceType() {
            return this.$store.state.deviceType;
        },
        userTwoFactorAuthMethodId() {
            return this.$store.state.user.twoFactorAuth.userTwoFactorAuthMethodId;
        },
        cipherSessionId() {
            return this.$store.state.user.twoFactorAuth.cipherSessionId;
        },
        verificationKey() {
            return this.$store.state.user.twoFactorAuth.verificationKey;
        },
        twoFactorMethod() {
            return this.$_twoFactorMixins_selectedTwoFactorMethod;
        },
        twoFactorMethodInVerification() {
            const { twoFactorMethod } = this;
            return twoFactorMethod && twoFactorMethod.twoFactorValidated === false ? twoFactorMethod : null;
        },
        isInVerification() {
            return Boolean(this.twoFactorMethodInVerification);
        },
        oneTimePassword: {
            get() {
                return this.user.twoFactorAuth.oneTimePassword;
            },
            set(oneTimePassword) {
                this.$store.commit(Types.mutations.SET_VERIFICATION_OTP, oneTimePassword);
            },
        },
        validated() {
            return this.twoFactorAuth.twoFactorEmailValidated || false;
        },
    },
    mounted() {
        this.saving = false;
    },
    methods: {
        back() {
            this.$store.commit(Types.mutations.SET_USER_TWO_FACTOR_AUTH_METHOD_ID, null);
            this.$store.commit(Types.mutations.SET_VERIFICATION_KEY, null);
            this.$store.commit(Types.mutations.SET_CIPHER_SESSION_ID, null);
            this.$store.commit(Types.mutations.SET_VERIFICATION_OTP, null);
        },
        validateOTP() {
            const v = this;
            if (v.saving) return;
            v.saving = true;

            const { twoFactorMethod } = this;
            if (!twoFactorMethod) return;
            const params = {
                body: {
                    cipherSessionId: v.cipherSessionId,
                    verificationKey: v.verificationKey,
                    sessionLength: v.sessionLength,
                    otp: v.oneTimePassword,
                    check: twoFactorMethod.userTwoFactorAuthMethodId,
                },
            };

            network.cipher.validateOneTimePassword(params, (err, res) => {
                v.saving = false;
                const handleError = function (e) {
                    window.console.error(e);
                    return v.showError('An authentication error has occurred. Refresh the page and try again');
                };
                if (err) return handleError(err);

                if (res.verificationKey !== v.verificationKey) return handleError('Key mismatch');

                if (v.isInVerification) {
                    // is there a better way to unlock and resume without reload?
                    window.location.reload();
                } else {
                    // unlock
                    v.$store.commit(Types.mutations.SET_CIPHER_SESSION_ID, res.cipherSessionId);
                    v.$store.commit(Types.mutations.SET_CIPHER_EPOCH_EXPIRY, res.cipherEpochExpiry);
                    v.$store.commit(Types.mutations.SET_VERIFICATION_KEY, null);
                    window.location.reload();
                }
            });
        },
    },
});

</script>

<style scoped>

div.one-time-pass input {
    letter-spacing: 1rem;
}
</style>

<style>
div.one-time-pass input[type="number"]::-webkit-outer-spin-button,
div.one-time-pass input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
div.one-time-pass input[type="number"] {
    -moz-appearance: textfield;
}
</style>
