<template>
<div
    :key="key"
    class="kt-portlet kt-portlet--mobile"
>
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon">
                <SVGIcon name="encrypted" />
            </span>
            <h3 class="kt-portlet__head-title">
                {{ isInVerification ? 'Verify Account' : "Account Locked" }}
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="form-group row">
            <label class="col-lg-4 col-form-label mt-4">
                Authentication Method:
            </label>
            <div class="col-lg-8">
                <div class="kt-radio-list">
                    <label
                        v-for="method in twoFactorMethods"
                        :key="`userTwoFactorAuthMethodId_${method.userTwoFactorAuthMethodId}`"
                        class="kt-radio my-4"
                        :class="method.userTwoFactorAuthMethodId == userTwoFactorAuthMethodId ? 'kt-radio--brand' : ''"
                    >
                        <input
                            v-model="userTwoFactorAuthMethodId"
                            type="radio"
                            name="userTwoFactorAuthMethodId"
                            :value="method.userTwoFactorAuthMethodId"
                        >
                        <div>
                            <span class="mr-3">
                                {{ method.twoFactorType }}:
                            </span>
                            {{ method.twoFactorAddress }}
                        </div>
                        <span />
                    </label>
                </div>
                <div
                    v-if="twoFactorMethod"
                    class="form-text text-muted"
                >
                    <template v-if="twoFactorMethod.twoFactorType == 'Email'">
                        An email containing a one time password will be sent to
                        {{ twoFactorMethod.twoFactorAddress }}
                    </template>
                    <template v-if="twoFactorMethod.twoFactorType == 'SMS'">
                        A text message containing a one time password will be sent to
                        {{ twoFactorMethod.twoFactorAddress }}
                    </template>
                </div>
                <div
                    v-else
                    class="form-text text-muted"
                >
                    Select an authentication method for a one time password.
                </div>
            </div>
        </div>
    </div>
    <div class="kt-portlet__foot">
        <div>
            <button
                class="btn btn-primary pull-right"
                :disabled="saving || !twoFactorMethod"
                :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                @click.stop.prevent="unlock"
            >
                <i
                    v-if="!saving"
                    class="fa fa-lock"
                />
                Continue
            </button>
            <button
                v-if="!isInVerification"
                class="btn btn-secondary"
                @click.stop.prevent="logout"
            >
                <i class="fa fa-power-off" />
                Logout
            </button>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import { mapGetters } from 'vuex';
import Vue from 'vue';
import * as network from '../../network';
import Types from '../../store/Types';
import twoFactorMixins from '../../store/mixins/twoFactorMixins';

export default Vue.extend({
    name: 'RequestOneTimePassword',
    mixins: [
        twoFactorMixins,
    ],
    data() {
        return {
            key: 0,
            saving: false,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        deviceType() {
            return this.$store.state.deviceType;
        },
        userTwoFactorAuthMethodId: {
            get() {
                return this.$store.state.user.twoFactorAuth.userTwoFactorAuthMethodId;
            },
            set(val) {
                this.$store.commit(Types.mutations.SET_USER_TWO_FACTOR_AUTH_METHOD_ID, val);
            },
        },
        verificationKey() {
            return this.$store.state.user.twoFactorAuth.verificationKey;
        },
        twoFactorMethod() {
            return this.$_twoFactorMixins_selectedTwoFactorMethod;
        },
        isInVerification() {
            if (!this.twoFactorMethod) return false;
            return Boolean(this.twoFactorMethod.twoFactorValidated === false);
        },
        ...mapGetters({
            twoFactorEnabled: Types.getters.IS_TWO_FACTOR_AUTH_ENABLED,
        }),

        twoFactorMethods() {
            return this.$_twoFactorMixins_twoFactorAuthMethods;
        },
        validated() {
            return this.twoFactorEmailValidated == this.twoFactorEmailAddress
                && this.twoFactorPhoneValidated == this.twoFactorPhoneNumber;
        },
    },
    watch: {
        twoFactorMethods() {
            const { isInVerification } = this;
            if (isInVerification) {
                this.unlock();
            }
        },
    },
    methods: {
        logout() {
            window.location.href = '/logout';
        },
        unlock() {
            const v = this;
            if (v.saving) return;

            v.saving = true;
            const params = {
                url: {
                    userTwoFactorAuthMethodId: v.userTwoFactorAuthMethodId,
                },
            };
            network.cipher.initiateChallenge(params, (err, res) => {
                v.saving = false;
                const handleError = function (e) {
                    window.console.error(e);
                    return v.showError('An authentication error has occurred. Refresh the page and try again');
                };
                if (err) return handleError(err);
                if (!res.verificationKey) return handleError('Invalid response from server');

                const { cipherSessionId, verificationKey, cipherEpochExpiry } = res;

                v.$store.commit(Types.mutations.SET_CIPHER_SESSION_ID, cipherSessionId);
                v.$store.commit(Types.mutations.SET_CIPHER_EPOCH_EXPIRY, cipherEpochExpiry);
                v.$store.commit(Types.mutations.SET_VERIFICATION_KEY, verificationKey);
            });
        },
    },
});

</script>

<style scoped>

div.one-time-pass input {
    width: 180px;
    height: 40px;
    font-size: 1.3rem;
    letter-spacing: 1rem;
}
</style>

<style>
div.one-time-pass input[type="number"]::-webkit-outer-spin-button,
div.one-time-pass input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
div.one-time-pass input[type="number"] {
    -moz-appearance: textfield;
}
</style>
