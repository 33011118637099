var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.twoFactorMethod
    ? _c(
        "div",
        { key: _vm.key, staticClass: "kt-portlet kt-portlet--mobile" },
        [
          _c("div", { staticClass: "kt-portlet__head" }, [
            _c("div", { staticClass: "kt-portlet__head-label" }, [
              _c(
                "span",
                { staticClass: "kt-port let__head-icon" },
                [_c("SVGIcon", { attrs: { name: "encrypted" } })],
                1
              ),
              _c("h3", { staticClass: "kt-portlet__head-title" }, [
                _vm._v(" Confirm Account Access "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "kt-portlet__body" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                _vm._v("One Time Password:"),
              ]),
              _c("div", { staticClass: "col-lg-6 one-time-pass" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.oneTimePassword,
                      expression: "oneTimePassword",
                    },
                  ],
                  staticClass: "text-center form-control",
                  attrs: { type: "number", step: "1" },
                  domProps: { value: _vm.oneTimePassword },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.validateOTP.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.oneTimePassword = $event.target.value
                    },
                  },
                }),
                _c("div", { staticClass: "form-text text-muted" }, [
                  _vm._v(
                    " A code has been sent to " +
                      _vm._s(_vm.twoFactorMethod.twoFactorAddress) +
                      " please enter it above. "
                  ),
                ]),
              ]),
            ]),
            !_vm.isInVerification
              ? _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                    _vm._v("Session Length:"),
                  ]),
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("div", { staticClass: "kt-radio-inline" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "kt-radio kt-radio--tick kt-radio--warning",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sessionLength,
                                expression: "sessionLength",
                              },
                            ],
                            attrs: { type: "radio", value: "180" },
                            domProps: {
                              checked: _vm._q(_vm.sessionLength, "180"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.sessionLength = "180"
                              },
                            },
                          }),
                          _vm._v(" 3 hours "),
                          _c("span"),
                        ]
                      ),
                      _c(
                        "label",
                        {
                          staticClass:
                            "kt-radio kt-radio--tick kt-radio--danger",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sessionLength,
                                expression: "sessionLength",
                              },
                            ],
                            attrs: { type: "radio", value: "480" },
                            domProps: {
                              checked: _vm._q(_vm.sessionLength, "480"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.sessionLength = "480"
                              },
                            },
                          }),
                          _vm._v(" 8 hours "),
                          _c("span"),
                        ]
                      ),
                      _c(
                        "label",
                        {
                          staticClass:
                            "kt-radio kt-radio--tick kt-radio--success",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sessionLength,
                                expression: "sessionLength",
                              },
                            ],
                            attrs: { type: "radio", value: "1440" },
                            domProps: {
                              checked: _vm._q(_vm.sessionLength, "1440"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.sessionLength = "1440"
                              },
                            },
                          }),
                          _vm._v(" 1 Day "),
                          _c("span"),
                        ]
                      ),
                      _c(
                        "label",
                        {
                          staticClass:
                            "kt-radio kt-radio--tick kt-radio--primary",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sessionLength,
                                expression: "sessionLength",
                              },
                            ],
                            attrs: { type: "radio", value: "10080" },
                            domProps: {
                              checked: _vm._q(_vm.sessionLength, "10080"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.sessionLength = "10080"
                              },
                            },
                          }),
                          _vm._v(" 1 Week "),
                          _c("span"),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "form-text text-muted" }, [
                      _vm._v(
                        " Please make sure this is a secured private computer inaccessible to students if requesting an extended session. "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "kt-portlet__foot" }, [
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-success pull-right",
                  class: {
                    "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                  },
                  attrs: { disabled: _vm.saving },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.validateOTP.apply(null, arguments)
                    },
                  },
                },
                [
                  !_vm.saving
                    ? _c("i", { staticClass: "fa fa-lock" })
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(_vm.isInVerification ? "Verify" : "Unlock") +
                      " "
                  ),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.back.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-history" }),
                  _vm._v(" Request a New Code "),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }