<template>
<div
    :key="key"
    class="kt-container kt-grid__item kt-grid__item--fluid row pt-4 mt-5"
>
    <div
        :class="{
            'col-8 offset-2': deviceType == 'desktop' || deviceType == 'laptop',
            'col-8 offset-2 pt-5': deviceType == 'tablet',
            'col-12 pt-5 pl-2 pr-2': deviceType == 'mobile',
        }"
    >
        <ChallengeOneTimePassword v-if="verificationKey" />
        <RequestOneTimePassword v-else />
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import ChallengeOneTimePassword from './ChallengeOneTimePassword.vue';
import RequestOneTimePassword from './RequestOneTimePassword.vue';
import twoFactorMixins from '../../store/mixins/twoFactorMixins';

export default Vue.extend({
    name: 'OneTimePassword',
    components: {
        ChallengeOneTimePassword,
        RequestOneTimePassword,
    },
    mixins: [
        twoFactorMixins,
    ],
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        deviceType() {
            return this.$store.state.deviceType;
        },
        userTwoFactorAuthMethodId() {
            return this.$store.state.user.twoFactorAuth.userTwoFactorAuthMethodId;
        },
        verificationKey() {
            return this.$store.state.user.twoFactorAuth.verificationKey;
        },
        twoFactorMethodInVerification() {
            const authMethod = this.$_twoFactorMixins_selectedTwoFactorMethod;
            return authMethod.twoFactorValidated === false ? authMethod : null;
        },
        isInVerification() {
            return Boolean(this.twoFactorMethodInVerification);
        },
    },
});

</script>

<style scoped>

div.one-time-pass input {
    width: 180px;
    height: 40px;
    font-size: 1.3rem;
    letter-spacing: 1rem;
}
</style>

<style>
div.one-time-pass input[type="number"]::-webkit-outer-spin-button,
div.one-time-pass input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
div.one-time-pass input[type="number"] {
    appearance: textfield;
}
</style>
