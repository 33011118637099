var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      key: _vm.key,
      staticClass:
        "kt-container kt-grid__item kt-grid__item--fluid row pt-4 mt-5",
    },
    [
      _c(
        "div",
        {
          class: {
            "col-8 offset-2":
              _vm.deviceType == "desktop" || _vm.deviceType == "laptop",
            "col-8 offset-2 pt-5": _vm.deviceType == "tablet",
            "col-12 pt-5 pl-2 pr-2": _vm.deviceType == "mobile",
          },
        },
        [
          _vm.verificationKey
            ? _c("ChallengeOneTimePassword")
            : _c("RequestOneTimePassword"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }